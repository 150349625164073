import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DonateurService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getDonateurs(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/donateurs`);
  }

  getLastThreeMonthsDonateurCount(): Observable<any[]> {
    //const url = `${this.apiUrl}/monthly-count`;
    //const url = `http://localhost:8000/api/donateurs/monthly-count`;
   // const url = `https://workflow.welyne.com/public/api/donateurs/monthly-count`;
    const url = `https://workflow.welyne.com/public/api/donateurs/last-three-months-count`;
    return this.http.get<any[]>(url);
  }
}
