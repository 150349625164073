import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'ngx-confirm-dialog',
    template: `
    <nb-card>
      <nb-card-header>Confirmation</nb-card-header>
      <nb-card-body>
        <p>Voulez-vous vraiment supprimer ce code postal ?</p>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="danger" (click)="dismiss(true)">Supprimer</button>
        <button nbButton status="basic" (click)="dismiss(false)">Annuler</button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class ConfirmDialogComponent {
    constructor(protected dialogRef: NbDialogRef<ConfirmDialogComponent>) { }

    dismiss(result: boolean) {
        this.dialogRef.close(result);
    }
}
