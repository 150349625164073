import { Component } from '@angular/core';
import { EchartsRendezvousBarComponent } from './echarts-rendezvous-bar-component';
import { EchartsRendezvousLineComponent } from './echarts-dashboard-line-component'; 

@Component({
  selector: 'app-echarts-dashboard',
  styleUrls: ['echarts-dashboard.component.scss'],
  templateUrl: './echarts-dashboard.component.html',
})
export class EchartsDashboardComponent {}
