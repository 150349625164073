import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Vérifiez si la méthode est appelée
    console.log('AuthGuard: canActivate called');

    const isLoggedIn = this.authService.isLoggedIn();

    console.log('User is logged in:', isLoggedIn);

    if (isLoggedIn) {
      return true;  
    } else {
      this.router.navigate(['/auth/connexion'], { queryParams: { returnUrl: state.url } });
      return false; 
    }
  }
}
