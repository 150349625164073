import { Component, OnInit } from '@angular/core';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
    selector: 'app-daywithmostcollections-chart',
    template: `
    <div class="summary-container">
    <nb-spinner *ngIf="isLoading"></nb-spinner>
    <div class="card-container" *ngIf="!isLoading">
      <nb-card class="number-card">
        <nb-card-header class="card-header">Jour avec le plus grand nombre de collectes pour cette semaine</nb-card-header>
        <nb-card-body class="card-body">
          <h2 class="card-count">{{ mostCollectionsDay?.days?.join(', ') }}</h2> 
          <p class="card-count">
            {{ mostCollectionsDay?.count }} 
            <span *ngIf="mostCollectionsDay?.count === 1">collecte</span>
            <span *ngIf="mostCollectionsDay?.count !== 1">collectes</span>
          </p>
        </nb-card-body>
      </nb-card>
    </div>
    </div>
  `,
    styles: [`
    .summary-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        height: 100%;
        overflow: hidden; 
    }

    .card-container {
        flex-grow: 1;  
        overflow: hidden; 
    }
    `]
})
export class DaysWithMostCollectionsChartComponent implements OnInit {

    mostCollectionsDay: { days: string[], count: number } | null = null;
    isLoading = true;

    constructor(private rendezVousService: RendezvousService) { }

    ngOnInit(): void {
        this.rendezVousService.getDayWithMostCollections().subscribe(data => {
            this.mostCollectionsDay = data;
            this.isLoading = false;
        });
    }
}
