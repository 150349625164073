<h1 id="title" class="title">Connexion</h1>
<p class="sub-title">Connectez-vous </p>

<!-- Affichage des messages d'erreur -->
<nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Erreur</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<!-- Affichage des messages de succès -->
<!--<nb-alert *ngIf="messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>-->

<!-- Formulaire de connexion -->
<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-email">Adresse email:</label>
        <input nbInput fullWidth [(ngModel)]="user.email" #email="ngModel" name="email" id="input-email"
            pattern=".+@.+\..+" placeholder="Adresse email" fieldSize="large" autofocus
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
            [required]="getConfigValue('forms.validation.email.required')"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
        <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">Adresse email est obligatoire.</p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">Veuillez utiliser une adresse email valide
            </p>
        </ng-container>
    </div>

    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Mot de passe:</label>
            <!--<a class="forgot-password caption-2" routerLink="../request-password">Mot de passe oublié?</a>-->
        </span>
        <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
            id="input-password" placeholder="Mot de passe" fieldSize="large"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
            [required]="getConfigValue('forms.validation.password.required')"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')"
            [attr.aria-invalid]="password.invalid && password.touched ? true : null">
        <ng-container *ngIf="password.invalid && password.touched ">
            <p class="caption status-danger" *ngIf="password.errors?.required">Mot de passe est obligatoire!</p>
            <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                Votre mot de passe doit contenir de {{ getConfigValue('forms.validation.password.minLength') }} à {{
                getConfigValue('forms.validation.password.maxLength') }} caractères.
            </p>
        </ng-container>
    </div>

    <!--<div class="form-control-group accept-group">
        <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe">Sauvegarder mes informations</nb-checkbox>
    </div>-->

    <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted">
        Se connecter
    </button>
</form>

<!-- Section pour les liens sociaux -->
<!--<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
    or enter with:
    <div class="socials">
        <ng-container *ngFor="let socialLink of socialLinks">
            <a *ngIf="socialLink.link" [routerLink]="socialLink.link" [attr.target]="socialLink.target"
                [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
                <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
                <ng-template #title>{{ socialLink.title }}</ng-template>
            </a>
            <a *ngIf="socialLink.url" [attr.href]="socialLink.url" [attr.target]="socialLink.target"
                [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
                <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
                <ng-template #title>{{ socialLink.title }}</ng-template>
            </a>
        </ng-container>
    </div>
</section>
-->

<!-- Section d'enregistrement -->
<!--<section class="another-action" aria-label="Register">
    Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section>-->