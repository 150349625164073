import { Component, OnInit } from '@angular/core';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
  selector: 'app-average-weight-collected-chart',
  template: `
    <div class="summary-container">
      <nb-spinner *ngIf="isLoading"></nb-spinner>
      <div class="card-container" *ngIf="!isLoading">
        <nb-card class="number-card">
          <nb-card-header class="card-header">Poids moyen d'enlèvement pour la semaine courante</nb-card-header>
          <nb-card-body class="card-body">
            <!-- Affichage du contenu selon les collectes -->
            <ng-container *ngIf="!noWeightCollected; else noDataMessage">
              <h2 class="card-count">{{ averageWeight }} kg</h2>
              <p class="card-description">
                Poids moyen d'enlèvement calculé pour les rendez-vous finalisés.
              </p>
            </ng-container>
            <ng-template #noDataMessage>
              <p class="no-collections-message">
                0 kg car il y a aucune collecte finalisée cette semaine.
              </p>
            </ng-template>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  `,
  styles: [`
    .summary-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      height: 100%;
      overflow: hidden; 
    }

    .card-container {
      flex-grow: 1;  
      overflow: hidden; 
    }

    .no-collections-message {
      font-size: 23px;
      color: #FF0000;
      text-align: center;
    }
  `]
})
export class AverageWeightCollectedChartComponent implements OnInit {

  averageWeight: number | null = null;
  isLoading = true;
  noWeightCollected = false;

  constructor(private rendezvousService: RendezvousService) { }

  ngOnInit(): void {
    this.rendezvousService.getAverageWeightCollected().subscribe(data => {
      if (data?.average_weight === 0) {
        this.noWeightCollected = true;
      } else {
        this.averageWeight = data?.average_weight ?? null;
        this.noWeightCollected = false;
      }
      this.isLoading = false;
    });
  }
}
