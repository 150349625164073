<div class="row">
    <div class="col-lg-6 large-container">
        <nb-card>
            <nb-card-header>Nombre de donateurs inscrits pour les trois derniers mois</nb-card-header>
            <nb-card-body>
                <app-donateur-card></app-donateur-card>
            </nb-card-body>
        </nb-card>
        <nb-card>
            <nb-card-header>Nombre de rendez-vous pour les trois derniers mois</nb-card-header>
            <nb-card-body>
                <app-rendezvous-card></app-rendezvous-card>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-lg-6 large-container">
        <nb-card>
            <nb-card-header>Statistiques poids collecté par mois en kg</nb-card-header>
            <nb-card-body>
                <app-monthly-weight-chart></app-monthly-weight-chart>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 large-container">
        <nb-card>
            <nb-card-header>Statistiques des rendez-vous selon le statut</nb-card-header>
            <nb-card-body>
                <ngx-echarts-rendezvous-bar></ngx-echarts-rendezvous-bar>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-lg-6 small-container">
        <div class="column">
            <nb-card>
                <nb-card-body>
                    <app-daywithmostcollections-chart></app-daywithmostcollections-chart>
                </nb-card-body>
            </nb-card>
            <nb-card>
                <nb-card-body>
                    <app-average-weight-collected-chart></app-average-weight-collected-chart>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-lg-6 full-container">
        <nb-card>
            <nb-card-header>Statistiques nombre des rendez-vous par jour/mois</nb-card-header>
            <nb-card-body>
                <app-daily-collections-chart></app-daily-collections-chart>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 full-container">
        <nb-card>
            <nb-card-header>Statistiques des rendez-vous par mois</nb-card-header>
            <nb-card-body>
                <ngx-echarts-line></ngx-echarts-line>
            </nb-card-body>
        </nb-card>
    </div>
</div>