import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RendezvousService {

  //private apiUrl = 'http://localhost:8000/api';
  private apiUrl = 'https://workflow.welyne.com/public/api';


  constructor(private http: HttpClient) { }

  getRendezvousByStatus(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/status-count`);
  }

  getMonthlyRendezvous(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/monthly-count`);
  }

  getMonthlyWeightCollected(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/monthly-weight`);
  }

  getLastThreeMonthsRendezvousCount(): Observable<any[]> {
    //const url = `${this.apiUrl}/monthly-count`;
    const url = `https://workflow.welyne.com/public/api/last-three-months-rendez-vous-count`;
    return this.http.get<any[]>(url);
  }

  getDayWithMostCollections(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/day-with-most-collections`);
  }

  getAverageWeightCollected(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/average-weight-collected`);
  }

  getDailyCollectionsByMonth(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/daily-collections-by-month`);
  }
}
