import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';  // Utilisez votre AuthService
//import { NbAuthSocialLink } from '../../auth.options';  // Si vous utilisez encore les socialLinks, gardez cela


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    redirectDelay = 0;
    showMessages: any = {};
    strategy = '';
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};  // Contiendra les informations de login (email, password)
    submitted = false;
    //socialLinks: NbAuthSocialLink[] = [];  // Gardez-le si vous avez des boutons sociaux
    rememberMe = false;

    customErrorMessages = {
        'invalid_credentials': 'Les informations d\'identification sont incorrectes. Veuillez vérifier votre email et mot de passe.',
        'network_error': 'Un problème de connexion est survenu. Veuillez réessayer plus tard.',
        'server_error': 'Le serveur a rencontré un problème. Veuillez réessayer.'
    };

    constructor(
        private authService: AuthService,  // Remplacez NbAuthService par AuthService
        private cd: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute // Injecter ActivatedRoute
    ) { }

    // Méthode de login
    login(): void {
        this.errors = [];
        this.messages = [];
        this.submitted = true;

        this.authService.login(this.user).subscribe({
            next: (result) => {
                this.submitted = false;
                this.messages.push('Login successful');

                this.router.navigate(['/pages/statistiques']);  
            },
            error: (err) => {
                this.submitted = false;
                const errorType = this.getErrorType(err);
                const errorMessage = this.customErrorMessages[errorType] || 'Une erreur inconnue est survenue.';
                this.errors.push(errorMessage);
                this.cd.markForCheck();
            },
        });
    }


    getErrorType(err: any): string {
        if (err.status === 401) {
            return 'invalid_credentials';
        } else if (err.status === 0) {
            return 'network_error';
        } else if (err.status >= 500) {
            return 'server_error';
        } else {
            return 'unknown_error';
        }
    }

    // Utilisé pour la validation des configurations dans le template
    getConfigValue(key: string): any {
        return null;  // Remplacez ceci par les valeurs de configuration que vous souhaitez utiliser
    }
}

