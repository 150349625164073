import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { RendezvousService } from '../../services/rendezvous.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-echarts-rendezvous-bar',
  template: `
  <div class="summary-container no-data">
      <nb-spinner *ngIf="isLoading" status="info"></nb-spinner>
      <div *ngIf="!isLoading">
        <div *ngIf="statusKeys.length > 0; else noDataMessage">
          <div class="status-container">
            <div class="status-item" *ngFor="let status of statusKeys">
              <div class="status-color" [style.backgroundColor]="statusColors[status]"></div>
              <span class="status-name">{{ statusMapping[status] }}</span>
              <span class="status-count">{{ data[status] || 0 }}</span>
            </div>
          </div>
          <div echarts [options]="options" class="echart"></div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .summary-container {
      display: flex;
      flex-direction: column;
      border-left: none;
      border-right: none;
      height: 485px;
    }

    nb-spinner {
      background: none;
    }

    .status-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .status-item {
      display: flex;
      align-items: center; 
      text-align: center;
      flex: 1;
    }

    .status-color {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      border-radius: 4px; 
    }

    .status-name {
      font-weight: 500;
    }

    .status-count {
      font-weight: bold;
      margin-left: 5px; 
    }

    .echart {
      width: 100%;
      height: 400px;
    }

    /*.summary-container.no-data {
      height: 100px;
    }*/

    
  `]
})
export class EchartsRendezvousBarComponent implements AfterViewInit, OnDestroy {
  options: any = {};
  themeSubscription: Subscription;
  data: any = {};
  statusKeys: string[] = [];
  isLoading: boolean = true;

  // Couleurs personnalisées pour chaque statut
  statusColors: { [key: string]: string } = {
    'INITIAL': '#b5b5b5', // gris
    'CONFIRME': '#005C96', // bleu clair
    'EN_ROUTE': '#eab676', // beige/orange
    'FINALISE': '#7BBB6D', // vert
    'CHUTE': '#F54234', // rouge
    'SIGNALE': '#ffff00', // jaune
    'ANNULE': '#F58529' // orange
  };

  statusMapping: { [key: string]: string } = {
    'INITIAL': 'Initial', 
    'CONFIRME': 'Confirmé', 
    'EN_ROUTE': 'En Route',
    'FINALISE': 'Finalisé', 
    'CHUTE': 'Chuté', 
    'SIGNALE': 'Signalé', 
    'ANNULE': 'Annulé'
  };

  statusOrder = ['INITIAL', 'CONFIRME', 'EN_ROUTE', 'FINALISE', 'CHUTE', 'SIGNALE', 'ANNULE'];

  constructor(private theme: NbThemeService, private rendezvousService: RendezvousService) { }

  ngAfterViewInit() {
    this.rendezvousService.getRendezvousByStatus().subscribe(statusData => {
      this.data = statusData;
      this.statusKeys = Object.keys(this.data).sort((a, b) => this.statusOrder.indexOf(a) - this.statusOrder.indexOf(b));
  
      // Si aucun rendez-vous n'est trouvé, on initialise data avec des valeurs à 0 pour chaque statut
      if (this.statusKeys.length === 0) {
        this.statusKeys = this.statusOrder;  // Pour afficher tous les statuts
        this.statusKeys.forEach(status => {
          this.data[status] = 0;  // On définit la valeur de chaque statut à 0
        });
      }
  
      this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
        const echarts: any = config.variables.echarts;
        this.options = {
          backgroundColor: echarts.bg,
          tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
          xAxis: {
            type: 'category',
            data: this.statusKeys.map(status => this.statusMapping[status]),
            axisLine: { lineStyle: { color: echarts.axisLineColor } },
            axisLabel: { textStyle: { color: echarts.textColor } },
          },
          yAxis: {
            type: 'value',
            axisLine: { lineStyle: { color: echarts.axisLineColor } },
            axisLabel: { textStyle: { color: echarts.textColor } },
            min: 0,
            max: (value) => Math.max(5, value.max),
            minInterval: 1,
          },
          series: [{
            name: 'Rendez-vous',
            type: 'bar',
            data: this.statusKeys.map(key => this.data[key] || 0),
            itemStyle: {
              color: (params) => this.statusColors[this.statusKeys[params.dataIndex]] || '#000', // Utilise la couleur spécifique au statut
            },
          }],
        };
  
        this.isLoading = false;
      });
    });
  }
  

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }
}
