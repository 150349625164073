import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
    selector: 'app-monthly-weight-chart',
    template: ` 
    <div class="summary-container" [ngStyle]="{'height': isDataEmpty ? '100px' : '417px'}">
        <nb-spinner *ngIf="isLoading"></nb-spinner>
        <div *ngIf="isDataEmpty" class="no-data-message">
            Aucune collecte trouvée pour afficher le graphique.
        </div>
        <div id="monthly-weight-chart" *ngIf="!isDataEmpty" style="width: 100%; height: 100%;"></div>
    </div>
    `,
    styles: [`
        .summary-container {
          display: flex;
          flex-direction: column;
          border-left: none;
          border-right: none;
          height: 417px;
        }
          
        .no-data-message {
          text-align: center;
          padding: 20px;
          color: #FF0000;
            font-size: 23px;
        }
          `]
})
export class MonthlyWeightChartComponent implements OnInit {
    chartInstance: any;
    chartData: any = {};
    isLoading: boolean = true;
    isDataEmpty: boolean = false;

    monthNames: string[] = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    colorPalette: string[] = [
        '#5470C6', '#91CC75', '#EE6666', '#FAC858', '#73C0DE', '#3BA272',
        '#FC8452', '#9A60B4', '#EA7CCC', '#FF9F7F', '#00C5CD', '#7B68EE'
    ];

    constructor(private rendezvousService: RendezvousService) { }

    ngOnInit(): void {
        this.loadMonthlyWeightData();
    }

    loadMonthlyWeightData(): void {
        this.isLoading = true;
        this.rendezvousService.getMonthlyWeightCollected().subscribe(
            (data) => {
                if (Array.isArray(data) && data.length === 0) {
                    this.isDataEmpty = true; 
                } else {
                    this.chartData = data;
                    this.initChart();
                    this.isDataEmpty = false; 
                }
                this.isLoading = false;
            },
            (error) => {
                console.error('Erreur lors du chargement des données :', error);
                this.isDataEmpty = true; 
                this.isLoading = false;
            }
        );
    }

    initChart(): void {
        const chartDom = document.getElementById('monthly-weight-chart');
        this.chartInstance = echarts.init(chartDom);

        const seriesData = Object.entries(this.chartData).map(([month, weight], index) => ({
            name: this.monthNames[parseInt(month) - 1],
            value: weight,
            itemStyle: {
                color: this.colorPalette[index % this.colorPalette.length]
            }
        }));

        const option = {
            tooltip: {
                trigger: 'item',
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                top: 'bottom',
            },
            series: [
                {
                    name: 'Poids',
                    type: 'pie',
                    radius: '50%',
                    data: seriesData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        this.chartInstance.setOption(option);
    }
}
