import { Component, OnInit } from '@angular/core';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
  selector: 'app-average-weight-collected-chart',
  template: `
    <div class="summary-container">
    <nb-spinner *ngIf="isLoading"></nb-spinner>
    <div class="card-container" *ngIf="!isLoading">
      <nb-card class="number-card">
        <nb-card-header class="card-header">Poids moyen d'enlèvement pour la semaine courante</nb-card-header>
        <nb-card-body class="card-body">
          <h2 class="card-count">{{ averageWeight }} kg</h2>
          <p class="card-description">Poids moyen d'enlèvement calculé pour les rendez-vous finalisés.</p>
        </nb-card-body>
      </nb-card>
    </div>
    </div>
  `,
  styles: [`
    .summary-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        height: 100%;
        overflow: hidden; 
    }

    .card-container {
        flex-grow: 1;  
        overflow: hidden; 
    }
    `]
})
export class AverageWeightCollectedChartComponent implements OnInit {

  averageWeight: number | null = null;
  isLoading = true;

  constructor(private rendezvousService: RendezvousService) { }

  ngOnInit(): void {
    this.rendezvousService.getAverageWeightCollected().subscribe(data => {
      this.averageWeight = data.average_weight;
      this.isLoading = false;
    });
  }
}
