import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
    selector: 'ngx-echarts-line',
    template: `
    <div class="summary-container">
        <nb-spinner *ngIf="isLoading" [size]="'large'"></nb-spinner>
        <div echarts [options]="options" class="echart" *ngIf="!isLoading"></div>
    </div>`,
    styles: [`
    .summary-container {
        display: flex;
        flex-direction: column;
        border-left: none;
        border-right: none;
    } 
    `]
})
export class EchartsRendezvousLineComponent implements AfterViewInit, OnDestroy {
    options: any = {};
    themeSubscription: Subscription;
    dataSubscription: Subscription;
    isLoading: boolean = true;

    statusLabels = {
        INITIAL: 'initial',
        EN_ROUTE: 'en route',
        FINALISE: 'finalisé',
        CHUTE: 'chuté',
        SIGNALE: 'signalé',
        ANNULE: 'annulé',
        CONFIRME: 'confirmé'
    };

    // Associez chaque couleur à un statut spécifique
    customColors = {
        INITIAL: '#b5b5b5', // gris
        EN_ROUTE: '#eab676', // beige/orange
        FINALISE: '#7BBB6D', // vert
        CHUTE: '#F54234', // rouge
        SIGNALE: '#ffff00', // jaune
        ANNULE: '#F58529', // orange
        CONFIRME: '#005C96' // bleu clair
    };

    constructor(private theme: NbThemeService, private rendezvousService: RendezvousService) { }

    ngAfterViewInit() {
        this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
            const colors: any = config.variables;
            const echarts: any = config.variables.echarts;
    
            this.isLoading = true;
    
            this.dataSubscription = this.rendezvousService.getMonthlyRendezvous().subscribe(monthlyData => {
                const series = [];
    
                // Vérifier si les données sont vides
                if (Object.keys(monthlyData).length === 0) {
                    // Si les données sont vides, créer une courbe avec 0 pour chaque mois
                    Object.keys(this.statusLabels).forEach((status) => {
                        const monthlyCounts = new Array(12).fill(0); // Remplir avec 0 pour chaque mois
                        series.push({
                            name: `Rendez-vous ${this.statusLabels[status] || status}`,
                            type: 'line',
                            data: monthlyCounts,
                            lineStyle: {
                                color: this.customColors[status] || '#000',
                                width: 2
                            },
                            itemStyle: {
                                color: this.customColors[status] || '#000'
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            silent: false
                        });
                    });
                } else {
                    // Si les données ne sont pas vides, traiter comme prévu
                    Object.keys(monthlyData).forEach((status) => {
                        const monthlyCounts = new Array(12).fill(0);
    
                        Object.keys(monthlyData[status]).forEach((month: string) => {
                            monthlyCounts[parseInt(month, 10) - 1] = monthlyData[status][month];
                        });
    
                        series.push({
                            name: `Rendez-vous ${this.statusLabels[status] || status}`,
                            type: 'line',
                            data: monthlyCounts,
                            lineStyle: {
                                color: this.customColors[status] || '#000',
                                width: 2
                            },
                            itemStyle: {
                                color: this.customColors[status] || '#000'
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            silent: false
                        });
                    });
                }
    
                this.options = {
                    backgroundColor: echarts.bg,
                    tooltip: { trigger: 'axis' },
                    legend: {
                        left: 'left',
                        data: series.map(s => s.name),
                        textStyle: { color: echarts.textColor },
                        selected: series.reduce((acc, curr) => {
                            acc[curr.name] = true;
                            return acc;
                        }, {})
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
                        axisLine: { lineStyle: { color: echarts.axisLineColor } },
                        axisLabel: { textStyle: { color: echarts.textColor } },
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: { lineStyle: { color: echarts.axisLineColor } },
                        axisLabel: { 
                            textStyle: { color: echarts.textColor },
                            formatter: (value: number) => {
                                return Math.floor(value); 
                            }
                        },
                        min: 0,
                        max: (value) => Math.max(5, value.max),
                        minInterval: 1,
                    },
                    series: series,
                };                
    
                this.isLoading = false;
            });
        });
    }
    

    ngOnDestroy(): void {
        this.themeSubscription.unsubscribe();
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}
