import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'app-error-dialog',
    template: `
    <nb-card>
      <nb-card-header>Erreur</nb-card-header>
      <nb-card-body>
        <p>{{ errorMessage }}</p>
      </nb-card-body>
      <nb-card-footer>
        <button type="button" class="btn btn-light" (click)="close()">Fermer</button>
      </nb-card-footer>
    </nb-card>
  `,
    styles: [`
    nb-card {
      max-width: 400px; /* Set a maximum width for the dialog */
      margin: auto; /* Center the dialog */
      border-color: #f5c6cb; /* Set the border color (red) */
      color: #721c24; /* Set text color (dark red) */
    }

    nb-card-header {
      color: #721c24; /* Set header text color */
      font-weight: bold; /* Make header text bold */
      text-align: center; /* Center the header text */
    }

    nb-card-body {
      padding: 20px; /* Adjust padding */
      font-size: 16px; /* Set font size */
      text-align: center; /* Center align text */
    }

    nb-card-footer {
      display: flex;
      justify-content: center; /* Center the button in the footer */
    }

    
  `]
})
export class ErrorDialogComponent {
    @Input() errorMessage: string;

    constructor(private dialogRef: NbDialogRef<ErrorDialogComponent>) { }

    close() {
        this.dialogRef.close();
    }
}
