import { Component, OnInit } from '@angular/core';
import { DonateurService } from '../../services/donateur.service';

@Component({
    selector: 'app-donateur-card',
    template: ` 
        <nb-spinner *ngIf="isLoading"></nb-spinner>
        <div class="card-container" *ngIf="!isLoading">
            <nb-card *ngFor="let card of cards" class="number-card">
                <nb-card-header class="card-header">{{ card.month }}</nb-card-header>
                <nb-card-body class="card-body">
                    <h2 class="card-count">{{ card.count }}</h2>
                </nb-card-body>
            </nb-card>
        </div>
    `,
    styles: [`
        .card-container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
        .number-card {
            flex: 1 0 10%; 
            min-width: 90px; 
            transition: transform 0.3s, box-shadow 0.3s; 
            background-color: #f9f9f9; 
            border: 1px solid #e0e0e0; 
            border-radius: 8px; 
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        }
        .number-card:hover {
            transform: translateY(-5px); 
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
        }
        .card-header {
            font-weight: bold; 
            background-color: #B0C4DE; 
            color: white; 
            text-align: center; 
        }
        .card-body {
            text-align: center; 
            padding: 10px; 
        }
        .card-count {
            font-size: 1.5rem; 
            color: #333; 
        }
    `]
})
export class DonateurChartComponent implements OnInit {

    cards: { month: string, count: number }[] = [];
    isLoading = true;

    monthNames = [
        '', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    constructor(private donateurService: DonateurService) { }

    ngOnInit(): void {
        this.donateurService.getLastThreeMonthsDonateurCount().subscribe(data => {
            this.createCards(data);
            this.isLoading = false;
        });
    }

    createCards(data: any[]): void {
        this.cards = data.map(d => ({
            month: this.monthNames[d.month],
            count: d.count
        }));
    }
}
