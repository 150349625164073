import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { RendezvousService } from '../../services/rendezvous.service';

@Component({
    selector: 'app-daily-collections-chart',
    template: `
      <nb-card-body>
        <div class="select-container">
            <label for="monthSelect">Sélectionnez un mois : </label>
            <select id="monthSelect" [(ngModel)]="selectedMonth" (change)="onMonthChange()" class="custom-select">
            <option *ngFor="let month of availableMonths" [value]="month.value">
                {{ month.label }}
            </option>
            </select>
        </div>
        <div echarts [options]="chartOptions" class="echart"></div>
      </nb-card-body>
    `,
    styles: [`
      .echart {
        width: 100%;
        //height: 400px;
        }
  
      .select-container {
        display: flex;
        justify-content: flex-end; 
        align-items: center;       
        }

        .custom-select {
        width: 160px;
        //padding: 8px;
        font-size: 16px;
        color: #333;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s ease;
        margin-left: 10px; 
        }

        .custom-select:focus {
        border-color: #5A9BD5;
        box-shadow: 0 0 5px rgba(90, 155, 213, 0.5);
        }

        .custom-select option {
        padding: 8px;
        }
    `]
  })
  
  export class DailyCollectionsChartComponent implements OnInit {
    isLoading = true;
    chartOptions: EChartsOption = {};
    selectedMonth: string; // Ne pas initialiser ici
    availableMonths = [
      { label: 'Janvier', value: '1' },
      { label: 'Février', value: '2' },
      { label: 'Mars', value: '3' },
      { label: 'Avril', value: '4' },
      { label: 'Mai', value: '5' },
      { label: 'Juin', value: '6' },
      { label: 'Juillet', value: '7' },
      { label: 'Août', value: '8' },
      { label: 'Septembre', value: '9' },
      { label: 'Octobre', value: '10' },
      { label: 'Novembre', value: '11' },
      { label: 'Décembre', value: '12' }
    ];
  
    constructor(private rendezVousService: RendezvousService) {}
  
    ngOnInit(): void {
      this.selectedMonth = (new Date().getMonth() + 1).toString(); // Initialiser avec le mois courant
      this.fetchData();
    }
  
    onMonthChange(): void {
      this.fetchData();
    }
  
    getDayName(year: number, month: number, day: number): string {
      const date = new Date(year, month - 1, day);
      const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
      return `${dayNames[date.getDay()]} ${day}`;
    }
  
    getDaysInMonth(month: number, year: number): number {
      return new Date(year, month, 0).getDate();
    }
  
    fetchData(): void {
      this.isLoading = true;
      this.rendezVousService.getDailyCollectionsByMonth().subscribe(data => {
        const dates: string[] = [];
        const collections: number[] = [];
        const currentYear = new Date().getFullYear();
        const daysInSelectedMonth = this.getDaysInMonth(parseInt(this.selectedMonth), currentYear);
  
        for (let day = 1; day <= daysInSelectedMonth; day++) {
          const dayLabel = this.getDayName(currentYear, parseInt(this.selectedMonth), day);
          dates.push(dayLabel);
          collections.push(0);
        }
  
        if (data[this.selectedMonth]) {
          Object.entries(data[this.selectedMonth]).forEach(([day, count]) => {
            const dayIndex = parseInt(day) - 1;
            collections[dayIndex] = count as number;
          });
        }
  
        this.chartOptions = {
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: dates,
            axisLabel: {
              interval: 0,
              rotate: 45,
              formatter: (value) => value,
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: (value) => Math.max(5, value.max),
            minInterval: 1,
          },
          series: [
            {
              name: 'Collections',
              type: 'line',
              data: collections,
              lineStyle: {
                color: '#7BBB6D'
              }
            }
          ]
        };
        this.isLoading = false;
      }, error => {
        console.error('Erreur lors du chargement des données:', error);
        this.isLoading = false;
      });
    }
  }
  