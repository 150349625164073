import { Component, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { CodepostalService } from '../../../services/codepostal.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  template: `
    <div>
      <nb-toggle [(checked)]="isActive" (change)="toggleStatus($event)"></nb-toggle>
      <span>{{ isActive ? 'Activé' : 'Désactivé' }}</span>
    </div>
  `,
  
})
export class BooleanColumnComponent implements ViewCell {
  @Input() value: any;
  @Input() rowData: any;  

  isActive: boolean = false;

  constructor(private codepostalService: CodepostalService, private toastrService: NbToastrService) { }

  ngOnInit() {
    //this.isActive = this.value === true || this.value === '1' || this.value === 1;
    this.isActive = this.value === true || this.value === '1' || this.value === 1 || this.rowData.active === true; // Ajoutez cette vérification

  }

  toggleStatus(event: any): void {
    // Update isActive based on toggle change
    this.isActive = event.target.checked;

    // Prepare the data to update the active status only
    const updatedStatus = {
      active: this.isActive
    };

    // Call updateActiveStatus from CodepostalService
    this.codepostalService.updateActiveStatus(this.rowData.id, updatedStatus).subscribe(
      (response) => {
        this.toastrService.success('Statut mis à jour', 'Succès');
      },
      (error) => {
        console.error('Erreur lors de la mise à jour du statut :', error);
        this.toastrService.danger('Erreur lors de la mise à jour du statut', 'Erreur');
      }
    );
  }

}
