import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  template: `
    <nb-card>
      <nb-card-header>Confirmation</nb-card-header>
      <nb-card-body>
        <p>Voulez-vous vraiment supprimer ce chauffeur ?</p>
      </nb-card-body>
      <nb-card-footer class="button-group">
        <button type="button" class="btn btn-primary" (click)="dismiss(true)">Supprimer</button>
        <button type="button" class="btn btn-light" (click)="dismiss(false)">Annuler</button>
      </nb-card-footer>
    </nb-card>
  `,styles: [`
    .button-group {
      display: flex;
      justify-content: center; 
      gap: 1rem; 
      padding-top: 1rem; 
    }
      `]

})
export class ConfirmDialogSuppressionChauffeurComponent {
  constructor(protected dialogRef: NbDialogRef<ConfirmDialogSuppressionChauffeurComponent>) { }

  dismiss(result: boolean) {
    this.dialogRef.close(result);
  }
}
